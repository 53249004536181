import { getSiteMeta } from '../lib/api';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import * as React from 'react';
import Head from '@components/Head';
import Layout from '../layouts';
import NotFound from '@components/NotFound';

export default function NotFoundPage(props: InferGetStaticPropsType<typeof getStaticProps>) {
	return (
		<Layout siteMeta={props.siteMeta} isPreview={props.preview}>
			<Head title={`Not Found | BUCK`} />
			<NotFound />
		</Layout>
	);
}

export const getStaticProps = async (props: GetStaticPropsContext<{ slug: string }>) => {
	const siteMeta = await getSiteMeta(props.preview);

	return {
		props: {
			preview: !!props.preview,
			siteMeta: siteMeta,
		},
		revalidate: 15,
	};
};
