import * as React from 'react';
import { GridItem, Text } from '@chakra-ui/react';
import { Grid8 } from './Grid';

export const NotFound = () => {
	return (
		<Grid8 minHeight={'50vh'}>
			<GridItem colStart={{ base: 1, sm: 3 }} colSpan={2}>
				<Text as="h1">Not Found</Text>
			</GridItem>
			<GridItem gridColumn={{ base: '1/-1', sm: '3/-1', lg: '6/-1' }}>
				<Text>{`The page you're looking for doesn't seem to exist.`}</Text>
			</GridItem>
		</Grid8>
	);
};

export default NotFound;
